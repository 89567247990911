export const sliderResults = [
  {
    scarcity: [
      "[1] We oversupply the demand.",
      "[2] It's broadly available and accessible.",
      "[3] It's known and available, but not very accessible.",
      "[4] You need to know the right people to get access.",
      "[5] Unless you're lucky, you won't find any info, invites, or products."
    ]
  },
  {
    novelty: [
      "[1] Though we like it, it's all been done before.",
      "[2] Hopefully it's seen to be newer than it is.",
      "[3] It's not a game-changer, but it's compelling.",
      "[4] Aspects of this are entirely original.",
      "[5] You've never seen anything like this before."
    ]
  },
  {
    influence: [
      "[1] This thing lives in a complete silo.",
      "[2] We're really just hoping this gets picked up and goes viral.",
      "[3] We have a modest budget to get the ball rolling.",
      "[4] We've got money to spend and Instagrammers to pay.",
      "[5] We have broad reach, third-party support, and an extensive media buy."
    ]
  }
];

export const sliderIntroText = [
  "Scarcity: How low is the actual (or perceived) availability?",
  "Novelty: Rate how unique or new your product is.",
  "Influence: How great is your reach? Consider all platforms, channels, and media."
];

export const endResults = [
  "You're hovering around the Absolute Zero of brand heat.",
  "If hype is a spice, this dish is bland. It's raw tofu.",
  "Not bad, but there's plenty of room to dial things up.",
  "That's some solid, honest, hard-working hype you've got there.",
  "You're breathing that rarefied, hype-scented air.",
  "You could roast a marshmallow on that brand heat."
];
