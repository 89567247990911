<template>
  <div v-if="show" class="mobile-rotate">
    <div class="bg-gradient"></div>
    <div class="img"></div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      show: false,
      isMobile: null
    };
  },
  mounted() {
    if (
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent
      )
    ) {
      this.isMobile = true;
    }
    window.addEventListener("orientationchange", this.handleOrientationChange);
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },
  methods: {
    handleOrientationChange: function() {
      if (screen.orientation.type === "landscape-primary") {
        this.showRotationScreen(true);
      } else {
        this.showRotationScreen(false);
      }
    },
    handleResize: function() {
      if (this.isMobile) {
        if (window.innerWidth > window.innerHeight) {
          this.showRotationScreen(true);
        } else {
          this.showRotationScreen(false);
        }
      }
    },
    showRotationScreen: function(bool) {
      this.show = bool ? true : false;
    }
  }
};
</script>

<style lang="scss" scoped>
.mobile-rotate {
  position: fixed;
  width: 100vw;
  height: 100vh;
  background: #fff;
  background: #9e9e9e url("../assets/bg-dot.png");
}
.bg-gradient {
  position: fixed;
  background-image: url("../assets/bg-gradient.png");
  background-size: cover;
  background-position: center center;
  width: 100vw;
  height: 100vh;
  z-index: -1;
}
.img {
  position: relative;
  left: 50%;
  top: 50%;
  background-color: orange;
  transform: translate(-50%, -50%);
  width: 90%;
  height: 55%;
  background: url("../assets/mobile-rotate.png");
  background-repeat: no-repeat;
  background-position: center center;
  background-size: contain;
}
</style>
