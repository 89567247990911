<template>
  <div class="sliders-container">
    <!-- Scarcity -->
    <div class="slider0 slider-container">
      <div class="slider-label"></div>
      <div class="slider-ticks"></div>
      <div class="slider-track border"></div>
      <input
        type="range"
        min="1"
        max="5"
        v-model="scarcity"
        @change="updateScarcity"
        class="slider"
      >
      <div class="slider-dark"></div>
      <div class="slider-active-container"></div>
    </div>
    <!-- Novelty -->
    <div class="slider1 slider-container border">
      <div class="slider-label"></div>
      <div class="slider-ticks"></div>
      <div class="slider-track border"></div>
      <input type="range" min="1" max="5" v-model="novelty" @change="updateNovelty" class="slider">
      <div class="slider-dark"></div>
      <div class="slider-active-container"></div>
    </div>
    <!-- Influence -->
    <div class="slider2 slider-container border">
      <div class="slider-label"></div>
      <div class="slider-ticks"></div>
      <div class="slider-track border"></div>
      <input
        type="range"
        min="1"
        max="5"
        v-model="influence"
        @change="updateInfluence"
        class="slider"
      >
      <div class="slider-dark"></div>
      <div class="slider-active-container"></div>
    </div>
    <arrow></arrow>
  </div>
</template>

<script>
import EventBus from "../js/event-bus.js";
import Arrow from "./Arrow";
import "../js/results-controller.js";

export default {
  components: {
    Arrow
  },
  props: {
    ScarcitySlider: {
      type: [Number, String],
      default: 1
    },
    NoveltySlider: {
      type: [Number, String],
      default: 1
    },
    InfluenceSlider: {
      type: [Number, String],
      default: 1
    }
  },
  data() {
    return {
      scarcity: this.ScarcitySlider,
      novelty: this.NoveltySlider,
      influence: this.InfluenceSlider
    };
  },
  mounted() {
    EventBus.$on("changeSliderActive", data => {
      //  console.log(data.sliderNum, data.show);
      const el = document.querySelector(
        ".slider" + data.sliderNum + " .slider-active-container"
      );
      el.classList.add("show");
    });
    EventBus.$on("showArrow", payload => {
      if (!payload.show) TweenMax.to(".arrow", 0.1, { autoAlpha: 0 });
      else TweenMax.to(".arrow", 0.1, { x: this.getArrowX(payload), autoAlpha: 1 });
    });
    EventBus.$on("showDarkSliderPanel", data => {
      const el = document.querySelector(
        `.slider${data.sliderNum} .slider-dark`
      );
      if (data.show) {
        el.classList.add("show");
        TweenMax.to(el, 0.5, { alpha: 1 });
      } else {
        TweenMax.to(el, 1, {
          autoAlpha: 0,
          onCompleteParams: [el],
          onComplete: el => {
            el.style.display = "none";
            // el.classList.remove("show");
          }
        });
      }
    });
    TweenMax.to(".arrow", 0.5, { y: -17, yoyo: true, repeat: -1 });
  },
  methods: {
    getArrowX: function(payload) {
      let x;
      const sliderNum = payload.sliderNum;
      const divWidth = document.querySelector(".sliders-container").offsetWidth;
      x = divWidth * 0.335 * sliderNum;
      return x;
    },
    updateScarcity: function(value) {
      this.$emit("updateScarcity", value);
      EventBus.$emit("updateScarcity", this.scarcity);
      TweenMax.to(".slider1 .slider-track", 1, {
        backgroundColor: "#6bff82",
        delay: 4.2
      });
    },
    updateNovelty: function(value) {
      this.$emit("updateNovelty", value);
      EventBus.$emit("updateNovelty", this.novelty);
      TweenMax.to(".slider2 .slider-track", 1, {
        backgroundColor: "#6bff82",
        delay: 4.2
      });
    },
    updateInfluence: function(value) {
      this.$emit("updateInfluence", value);
      EventBus.$emit("updateInfluence", this.influence);
    }
  }
};
</script>

<style lang="scss" scoped>
@import "../styles/slider-component.scss";

.sliders-container {
  display: flex;
  position: relative;
}
.slider-container {
  position: relative;
  height: 225px;
  width: 33.3333%;
}
.slider0 .slider-label {
  // Scarcity
  background: url("../assets/scarcity.png") no-repeat;
  background-size: contain;
  width: 80%;
}
.slider1 .slider-label {
  // Novelty
  background: url("../assets/novelty.png") no-repeat;
  background-size: contain;
  width: 73%;
}
.slider2 .slider-label {
  // Influence
  background: url("../assets/influence.png") no-repeat;
  background-size: contain;
  width: 90%;
}
.slider-label {
  position: absolute;
  height: 40px;
  left: 50%;
  transform: translateX(-50%);
}
.slider-ticks {
  position: absolute;
  left: 20px;
  top: 62px;
  width: 30px;
  height: 135px;
  background-image: url("../assets/slider-ticks.png");
  background-repeat: no-repeat;
  background-size: contain;
}
.slider-track {
  position: absolute;
  left: 65px;
  top: 63px;
  width: 8px;
  height: 135px;
  background: #ccc;
  border-radius: 500px;
  border:1px #999 solid;
  box-shadow: inset 1px 0px 6px 0px rgba(0, 0, 0, 0.7);
}
.slider0 .slider-track {
  background: #95ffa9;
}
.slider-dark,
.slider-active-container {
  &.show {
    position: absolute;
    width: 100%;
    height: 225px;
    top: 0;
  }
}
.slider-dark {
  background: rgba(0, 0, 0, 0.4);
}
</style>
