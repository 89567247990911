import store from "../vue/store.js";

export function getLink() {
  let link = `mailto:thescienceofhype@annex88.com?subject=I'm%20looking%20to%20create%20some%20brand%20heat&body=Hey%20Annex88%2C%0AI'd%20love%20to%20talk%20more%20about%20dialing%20up%20the%20hype%20on%20my%20next%20project.%0A`;
  if (store.state.calculated)
    link = `mailto:thescienceofhype@annex88.com?subject=My%20Hype%20Score%20is%20${
      store.state.hypeValue
    }%20-%20let's%20talk&body=Hey%20Annex88%2C%0AI'd%20love%20to%20talk%20more%20about%20dialing%20up%20the%20hype%20on%20my%20next%20project.%0A`;
  return link;
}
