<template>
  <div v-show="state_visible" class="content-container">
    <div class="main">
      <hype-meter :hype-value="hype"></hype-meter>
      <div class="divider"></div>
      <results-display class></results-display>
      <div class="divider"></div>
      <div class="meter-wrapper">
        <meter-sliders
          @updateScarcity="updateScarcity"
          @updateNovelty="updateNovelty"
          @updateInfluence="updateInfluence"
          :scarcity-slider="scarcity"
          :novelty-slider="novelty"
          :influence-slider="influence"
          :class="{'hide':shareModal}"
        ></meter-sliders>
        <share-modal v-if="shareModal"></share-modal>
      </div>
      <div class="divider"></div>
      <foot class @updateFooterBtn="updateFooterBtn"></foot>
    </div>
  </div>
</template>
<script>
import EventBus from "../js/event-bus.js";
import HypeMeter from "./HypeMeter";
import MeterSliders from "./MeterSliders";
import ShareModal from "./ShareModal";
import ResultsDisplay from "./ResultsDisplay";
import Foot from "./Footer";
import { mapState } from "vuex";

export default {
  name: "App",
  data() {
    return {
      state_visible: false,
      scarcity: 1,
      novelty: 1,
      influence: 1
    };
  },
  components: {
    HypeMeter,
    ResultsDisplay,
    MeterSliders,
    ShareModal,
    Foot
  },
  computed: {
    ...mapState(["testCalculation", "testing", "shareModal"]),
    hype: function() {
      this.val1 = this.calucateHype1(
        this.scarcity,
        this.novelty,
        this.influence
      );
      this.$store.commit("changeHypeValue", this.val1);
      return this.val1;
    }
  },
  mounted() {
    EventBus.$on("SHOW_MODAL", bool => {
      if (bool) {
        this.state_visible = false;
      } else {
        this.state_visible = true;
      }
    });
    // print out all possible combination of hype scores
    // var i, j, k;
    // for (i = 0; i <= 5; i++) {
    //   for (j = 0; j <= 5; j++) {
    //     for (k = 0; k <= 5; k++) {
    //       // console.log("scarcity: " + i +" novelty: " + j +" influence: " + k +" = " +this.calucateHype(i, j, k));
    //     }
    //   }
    // }
    // this.calucateHype2(this.scarcity, this.novelty, this.influence);
  },
  methods: {
    updateScarcity: function(event) {
      const value = event.target.value;
      this.scarcity = value;
      //   console.log("updateScarcity", event, event.target.value);
    },
    updateNovelty: function(event) {
      const value = event.target.value;
      this.novelty = value;
      //   console.log("updateNovelty", event, event.target.value);
    },
    updateInfluence: function(event) {
      const value = event.target.value;
      this.influence = value;
      //   console.log("updateInfluence", event, event.target.value);
    },
    updateFooterBtn: function(event) {
      if (event === "share") {
        this.$store.commit("changeShareModal", !this.shareModal);
      }
      if (event === "contact") console.log("contact");
      if (event == "info") console.log("show info");
    },
    calucateHype1: function(a, b, c) {
      var _scarcity = a * 0.0008 - 0.005; //-0.005, -0.001(-0.03,-0.005)
      var _novelty = b * 0.02 + 0.1; //0.1, 0.2(0.1, 1)
      var _influence = c * 2 + 10; //10, 20(0, 10)
      var area = 0;
      var dir = Math.sqrt(Math.pow(_novelty, 2) - 4 * _scarcity * _influence);
      var x1 = (-_novelty + dir) / (2 * _scarcity);
      var x2 = (-_novelty - dir) / (2 * _scarcity);
      x2 = parseInt(x2);
      // //calculate area from 0 to x2
      var t = (x2 - 0) / 100;
      for (var k = 0; k < x2; k += t) {
        var posY = _scarcity * Math.pow(k, 2) + _novelty * k + _influence;
        area += t * Math.abs(posY);
      }
      area = parseInt(area);
      var value = ((area - 426) * 10) / 5731;
      //   console.log("was: " + value);
      value = value.toFixed(1);
      //   console.log("now: " + value);
      return value;
    }
    // calucateHype2: function(a, b, c) {
    //   // y=5a+3b+2c
    //   var value = 0.5 * a + 0.3 * b + 0.2 * c;
    //   value = value * 2;
    //   return value;
    // }
  }
};
</script>

<style lang="scss">
@import "../styles/main.scss";
.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
}
.main {
  position: absolute;
  top: 0;
  width: 100%;
}
// .test-wrapper {
//   position: absolute;
//   width: 100%;
//   height: 100px;
//   top: 0;
//   color: #aaa;
// }
// .test-text {
//   color: #666;
//   position: relative;
//   top: 10px;
//   left: 5px;
// }
// .hit-spot {
//   display: inline;
//   position: absolute;
//   top: 0;
//   width: 100%;
//   height: 120px;
//   cursor: pointer;
// }
.hide {
  opacity: 0;
}
.meter-wrapper {
  height: auto;
  position: relative;
}
</style>
