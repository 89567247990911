<template>
  <div class="wrapper">
    <div class="bg-gradient"></div>
    <div ref="app-window" :class="`app-window border scale-${scaleDir}`">
      <hype-logo></hype-logo>
      <div class="divider"></div>
      <div class="content-main">
        <main-content></main-content>
        <modal></modal>
      </div>
    </div>
    <mobile-rotate></mobile-rotate>
  </div>
</template>
<script>
import HypeLogo from "./HypeLogo";
import MainContent from "./Main";
import Modal from "./Modal";
import MobileRotate from "./MobileRotate";
import getViewport from "../js/get-viewport";
const idealRatio = 375 / 635;

export default {
  name: "App",
  components: {
    HypeLogo,
    MainContent,
    Modal,
    MobileRotate,
    screenRatio: 1
  },
  data() {
    return {
      scaleDir: "vert"
    };
  },
  mounted() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },
  methods: {
    handleResize: function() {
      this.screenRatio = getViewport()[0] / getViewport()[1];
      const stage = this.$refs["app-window"],
        stageHeight = stage.offsetHeight,
        windowHeight = window.innerHeight,
        stageWidth = stage.offsetWidth,
        windowWidth = window.innerWidth,
        windowRatio = windowHeight / windowWidth,
        ratio = windowWidth / windowHeight;
      let scale = windowHeight / stageHeight;
      if (scale > 1.4) scale = 1.4;
      if (this.screenRatio <= idealRatio) {
        this.scaleDir = "hor";
        scale = windowWidth / stageWidth;
        // console.log("hor");
      } else {
        this.scaleDir = "hor";
        // console.log("vert");
      }
      stage.style.transform = "scale(" + scale + ")";
    }
  }
};
</script>

<style lank="scss">
@import "../styles/main.scss";
.wrapper {
  position: fixed;
  top: 50%;
  transform: translateY(-50%);
  height: 100%;
  background: #9e9e9e url("../assets/bg-dot.png");
}
.app-window {
  position: absolute;
  width: 375px;
  height: 635px;
  background-image: url("../assets/bg.jpg");
  background-size: cover;
  overflow: hidden;
  box-shadow: 10px 10px 21px -7px rgba(0, 0, 0, 1);
}
.bg-gradient {
  background-image: url("../assets/bg-gradient.png");
  background-size: cover;
  background-position: center center;
  width: 100vw;
  height: 100vh;
}
.content-main {
  position: relative;
  width: 100%;
}
</style>
