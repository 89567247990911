import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    hypeValue: null,
    shareModal: false,
    calculated: false
  },
  mutations: {
    changeHypeValue(state, payload) {
      Vue.set(state, "hypeValue", payload);
    },
    changeShareModal(state, payload) {
      Vue.set(state, "shareModal", payload);
    },
    changeCalculated(state, payload) {
      // fires the first time the calculation happens
      Vue.set(state, "calculated", payload);
    }
  }
});
