<template>
  <div class="results-display">
    <div class="window-container">
      <div class="window border">
        <span class="txt"></span>
      </div>
    </div>
  </div>
</template>

<script>
import TypeIt from "typeit";
import { resultsData } from "../data/results-data.js";
import EventBus from "../js/event-bus.js";

let introTextIndex = 0;

export default {
  mounted() {
    EventBus.$on("changeDisplayTextFade", result => {
      const el = ".results-display .txt";
      document.querySelector(el).innerHTML = result;
      TweenMax.fromTo(
        el,
        1,
        { y: 10, alpha: 0 },
        { y: 0, alpha: 1, delay: 0.5, ease: Power4.easeOut }
      );
    });
    EventBus.$on("changeDisplayTextTyping", result => {
      let myTypeItInstance = new TypeIt(".results-display .txt", {
        strings: result,
        speed: 30
      });
      myTypeItInstance.go();
    });
    EventBus.$on("clearText", () => {
      const el = ".results-display .txt";
      document.querySelector(el).innerHTML = "";
    });
  }
};
</script>

<style lang="scss" scoped>
.window-container {
  width: 85%;
  height: 70px;
  margin: 5px auto;
  display: table;
}
.window {
  height: 70px;
  padding: 3px 10px;
  border-radius: 15px;
  display: table-cell;
  vertical-align: middle;
  background: #1a1a1a;
}
.txt {
  font-family: "VT323", monospace;
  font-size: 1.6rem;
  letter-spacing: -0.08rem;
  color: #7fe594;
  line-height: 0.8;
  text-align: center;
  display: inline-block;
}
</style>

