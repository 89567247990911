<template>
  <div class="microsoft-detect">
    <h1>Browser not supported.</h1>
    <p>Please view this experience with another browser to take our Science of Hype formula for a spin and see the method to the radness.</p>
  </div>
</template>

<script>
export default {
  mounted() {}
};
</script>

<style lang="scss" scoped>
.microsoft-detect {
  width: 100%;
  height: 100%;
  padding: 30px;
}
</style>

