import Vue from "vue";
import App from "../vue/App";
import store from "../vue/store.js";

export const vm = new Vue({
  el: "#vueApp",
  store,
  render: h => h(App),
  data() {
    return {};
  },
  mounted() {},
  methods: {}
});
