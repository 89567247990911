<template>
  <div>
    <div v-if="state_visible && !showMicrosoftDetectOverlay" class="modal">
      <div class="modal-container">
        <h1 class="animIn">There&#8217;s a science to the hype.</h1>
        <div>
          <p class="animIn">
            At
            <a href="http://www.annex88.com" target="_blank">Annex88</a>, we&#8217;ve developed a method to achieve serious brand heat for your project. Our formula is based on novelty, scarcity, and influence.
          </p>
          <p class="animIn">
            Take the formula for a spin to get an idea of the hype around your latest or upcoming project. If you&#8217;d like to
            <a
              :href="getURL()"
            >get in touch</a>, we&#8217;d love to help you dial up the brand heat.
          </p>
        </div>
        <div class="buttons">
          <div class="left-btn animIn">
            <div class="arr-container">
              <div class="arr"></div>
            </div>
            <div class="link-started">
              <a @click="showPanel(false)">Let&#8217;s get started</a>.
            </div>
          </div>
          <div @click="showPanel(false)" class="close-btn animIn"></div>
        </div>
      </div>
      <img class="gradient-bar" src="../assets/colour_bar.png">
    </div>
    <microsoft-detect v-if="showMicrosoftDetectOverlay"></microsoft-detect>
  </div>
</template>

<script>
import Vue from "vue";
import EventBus from "../js/event-bus.js";
import { getLink } from "../js/link-url.js";
import MicrosoftDetect from "./MicrosoftDetect";

export default {
  components: {
    MicrosoftDetect
  },
  props: {
    visible: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      state_visible: true,
      showMicrosoftDetectOverlay: false
    };
  },
  mounted() {
    const anims = document.querySelectorAll(".animIn");
    for (let i = 0; i < anims.length; i++) {
      const el = anims[i];
      TweenMax.fromTo(
        el,
        1,
        { alpha: 0, y: 20 },
        { alpha: 1, y: 0, delay: 0.8 + i * 0.2, ease: Power4.easeOut }
      );
    }
    EventBus.$on("SHOW_MODAL", bool => {
      this.state_visible = bool;
    });
    // console.log("Detecting Microsoft");
    //Microsoft detect
    if (/MSIE 10/i.test(navigator.userAgent)) {
      // IE 10
      console.log("IE10");
      this.showMicrosoftDetectOverlay = true;
    }
    if (
      /MSIE 9/i.test(navigator.userAgent) ||
      /rv:11.0/i.test(navigator.userAgent)
    ) {
      // IE 9 or 11
      console.log("IE 9 or 10");
      this.showMicrosoftDetectOverlay = true;
    }
    if (
      /Edge\/\d./i.test(navigator.userAgent) ||
      /Edge/.test(navigator.userAgent)
    ) {
      // Microsoft Edge
      console.log("Microsoft Edge");
      this.showMicrosoftDetectOverlay = true;
    }
  },
  methods: {
    getURL: function() {
      return getLink();
    },
    showPanel: function(bool) {
      EventBus.$emit("SHOW_MODAL", bool);
    }
  }
};
</script>

<style scoped type="scss">
.modal {
  display: flex;
  position: relative;
  top: 0;
  width: 100%;
  height: 523px;
}
.modal-container {
  padding: 30px;
}
.gradient-bar {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 50px;
}
h1 {
  margin-top: 0;
  margin-bottom: 0;
  font-weight: 700;
  font-size: 2.2rem;
  line-height: 0.93;
}
p {
  margin: 26px 0 0 0;
  font-size: 1.3rem;
}
.arr-container {
  position: absolute;
}
.arr {
  width: 0;
  height: 0;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  border-left: 17px solid #222;
}
.link-started {
  margin-left: 21px;
  font-size: 1.3rem;
  cursor: pointer;
}
.link-started a {
  text-decoration: underline;
}
.link-started a:hover {
  text-decoration: none;
}
.buttons {
  margin-top: 25px;
}
.close-btn {
  background-image: url("/assets/button_close.png");
  background-size: cover;
  background-repeat: no-repeat;
  margin-top: -36px;
  width: 60px;
  height: 60px;
  float: right;
  cursor: pointer;
}
</style>