<template>
  <div class="footer">
    <div class="btns-container">
      <div class="share btn-container">
        <div class="btn" @click="$emit('updateFooterBtn', 'share')" :class="{'close':shareModal}"></div>
        <div class="txt" @click="$emit('updateFooterBtn', 'share')">SHARE</div>
      </div>
      <div class="contact btn-container">
        <a class="btn" :href="getURL()"></a>
        <!-- <div class="txt">CONTACT US</div> -->
        <div class="txt"><a class="txt-link" :href="getURL()">GET IN TOUCH</a></div>
      </div>
      <div class="info btn-container">
        <div class="btn" @click="handleClick('info')"></div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import EventBus from "../js/event-bus.js";
import { getLink } from "../js/link-url.js";

export default {
  computed: {
    ...mapState(["hypeValue", "shareModal", "calculated"])
  },
  methods: {
    getURL: function() {
      return getLink();
    },
    handleClick: function(to) {
      switch (to) {
        case "info":
          EventBus.$emit("SHOW_MODAL", true);
          break;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.footer {
  position: relative;
  width: 100%;
  height: 35px;
}
.btns-container {
  margin-top: 17px;
}
.btn-container {
  position: absolute;
}
.btn {
  display: block;
  cursor: pointer;
}
.share.btn-container {
  left: 15px;
  width: 30%;
}
.contact.btn-container {
  left: 37%;
  width: 45%;
}
.info.btn-container {
  right: 15px;
}
.info .btn {
  background-image: url(../assets/button_info.png);
  width: 55px;
  height: 55px;
  background-size: cover;
}
.contact .btn,
.share .btn {
  width: 55px;
  height: 55px;
  background-image: url(../assets/button_blank.png);
  background-size: cover;
  &.close {
    background-image: url(../assets/button_close.png);
  }
}
.txt {
  position: absolute;
  top: 18px;
  left: 55px;
  font-size: 1rem;
  font-weight: 700;
  letter-spacing: -0.065rem;
  color: #999;
  white-space: nowrap;
  cursor: pointer;
}
.txt a {
    color:#999;
    text-decoration: none;
}
.bg {
  position: absolute;
  width: 94%;
  height: 90px;
  top: 10px;
  left: 3%;
  background-color: rgba(256, 256, 256, 1);
}
</style>
