import EventBus from "./event-bus";
import {
  sliderResults,
  sliderIntroText,
  endResults
} from "../data/results-data";
import store from "../vue/store";
import { getLink } from "./link-url.js";

const introTextStartDelay = 600,
  regularCalculatingHoldTime = 1500,
  introOutDelay = 5000,
  regularOutDelay = 4000,
  introCalculatingHoldTime = 1500;
// Testing
//   introOutDelay = 1200,
//   regularOutDelay = 1200,
//   introCalculatingHoldTime = 1200;
// Testing

let inIntro = true,
  allResults = null,
  timersArray = [],
  onLastSlider = false,
  releaseForCalculated = false;

EventBus.$on("updateScarcity", slider => {
  controlSlider(0, slider);
});
EventBus.$on("updateNovelty", slider => {
  controlSlider(1, slider);
});
EventBus.$on("updateInfluence", slider => {
  controlSlider(2, slider);
});

const controlSlider = (sliderNum, tickNum) => {
  let result = null;
  allResults = sliderResults[sliderNum];
  clearTimeouts();
  EventBus.$emit("animateFakeMeterMoveOut");

  tickNum--;
  if (sliderNum === 0) result = allResults.scarcity[tickNum];
  if (sliderNum === 1) result = allResults.novelty[tickNum];
  if (sliderNum === 2) result = allResults.influence[tickNum];

  // ========== NOT INTRO ==========
  // ===========================
  if (!inIntro) {
    clearText();
    EventBus.$emit("animateFakeMeterMoveOut");
    setTimeout(() => {
      changeTextTyping(result);
    }, 300);
    timersArray[0] = setTimeout(() => {
      changeTextTyping("Calculating...");
    }, regularOutDelay);
    timersArray[1] = setTimeout(() => {
      EventBus.$emit("moveDial");
      changeTextFade(getResultsText());
    }, regularOutDelay + regularCalculatingHoldTime);
  }

  // ========== INTRO ==========
  // ===========================
  if (inIntro) {
    // Move meter
    timersArray[2] = setTimeout(() => {
      EventBus.$emit("animateFakeMeterMoveIn", tickNum);
    }, 50);
    timersArray[3] = setTimeout(() => {
      EventBus.$emit("animateFakeMeterMoveOut");
    }, 700);
    // ========== Slider 0 ==========
    if (sliderNum === 0) {
      clearText();
      EventBus.$emit("showArrow", { show: false, sliderNum: 0 });
      timersArray[4] = setTimeout(() => {
        changeTextTyping(result);
      }, introTextStartDelay);
      timersArray[5] = setTimeout(() => {
        if (releaseForCalculated) {
          calculateFromIntroScreens();
        } else {
          if (onLastSlider) {
            EventBus.$emit("showArrow", { show: true, sliderNum: 2 });
          } else {
            EventBus.$emit("showArrow", { show: true, sliderNum: 1 });
          }
          showDarkSliderPanel(1, false);
          changeTextFade(sliderIntroText[1]);
        }
      }, introOutDelay);
      timersArray[6] = setTimeout(() => {}, introOutDelay);
    }
    // ========== Slider 1 ==========
    if (sliderNum === 1) {
      clearText();
      EventBus.$emit("showArrow", { show: false, sliderNum: 1 });
      timersArray[7] = setTimeout(() => {
        changeTextTyping(result);
      }, introTextStartDelay);
      timersArray[8] = setTimeout(() => {
        if (releaseForCalculated) {
          calculateFromIntroScreens();
        } else {
          EventBus.$emit("showArrow", { show: true, sliderNum: 2 });
          onLastSlider = true;
          showDarkSliderPanel(2, false);
          changeTextFade(sliderIntroText[2]);
        }
      }, introOutDelay);
      timersArray[9] = setTimeout(() => {}, introOutDelay);
    }
    // ========== Slider 2 ==========
    if (sliderNum === 2) {
      clearText();
      releaseForCalculated = true;
      EventBus.$emit("showArrow", { show: false, sliderNum: 2 });
      timersArray[10] = setTimeout(() => {
        changeTextTyping(result);
      }, introTextStartDelay);
      timersArray[11] = setTimeout(() => {}, introOutDelay);
      timersArray[12] = setTimeout(() => {
        store.commit("changeCalculated", true);
        calculateFromIntroScreens();
        // changeTextTyping("Calculating...");
      }, introOutDelay);
      //   timersArray[13] = setTimeout(() => {
      //     showDarkSliderPanel(0, false);
      //     setTimeout(() => {
      //       showDarkSliderPanel(1, false);
      //     }, 0.1);
      //     setTimeout(() => {
      //       showDarkSliderPanel(2, false);
      //     }, 0.2);
      //     showDarkSliderPanel(1, false);
      //     showDarkSliderPanel(2, false);
      //     EventBus.$emit("moveDial");
      //     changeTextFade(getResultsText());
      //     inIntro = false;
      //   }, introOutDelay + introCalculatingHoldTime);
    }
  }
};
const calculateFromIntroScreens = () => {
  changeTextTyping("Calculating...");
  timersArray[13] = setTimeout(() => {
    showDarkSliderPanel(0, false);
    setTimeout(() => {
      showDarkSliderPanel(1, false);
    }, 0.1);
    setTimeout(() => {
      showDarkSliderPanel(2, false);
    }, 0.2);
    showDarkSliderPanel(1, false);
    showDarkSliderPanel(2, false);
    EventBus.$emit("moveDial");
    changeTextFade(getResultsText());
    inIntro = false;
  }, introCalculatingHoldTime);
};
const returnLink = () => {
  return getLink();
};
const getResultsText = () => {
  const val = store.state.hypeValue,
    pointNum = parseFloat(val),
    fixed = pointNum.toFixed(1);
  const resultNum = getResultTextNum();
  return `[Score ${fixed}] ${
    endResults[resultNum]
  } <a class="contact-link" href="${returnLink()}">Get in touch.</a>`;
};
const clearText = () => {
  EventBus.$emit("clearText");
};
const showDarkSliderPanel = (sliderNum, show) => {
  EventBus.$emit("showDarkSliderPanel", {
    sliderNum,
    show
  });
};
const changeTextFade = result => {
  EventBus.$emit("changeDisplayTextFade", result);
};
const changeTextTyping = result => {
  EventBus.$emit("changeDisplayTextTyping", result);
};
const getResultTextNum = () => {
  const val = store.state.hypeValue;
  let i = null;
  if (val <= 1.1) i = 0;
  else if (val > 1.1 && val <= 3) i = 1;
  else if (val > 3 && val <= 5) i = 2;
  else if (val > 5 && val <= 7) i = 3;
  else if (val > 7 && val <= 9) i = 4;
  else if (val > 9 && val <= 10) i = 5;
  return i;
};
const clearTimeouts = () => {
  for (let i = 0; i < timersArray.length; i++) {
    clearTimeout(timersArray[i]);
  }
};

const init = () => {
  // Intro text
  setTimeout(() => {
    showDarkSliderPanel(0, false);
    showDarkSliderPanel(1, true);
    showDarkSliderPanel(2, true);
    changeTextFade(sliderIntroText[0]);
  }, 10);
};

init();
