<template>
    <div class="share-modal">
        <div>
            <a @click="social_click(facebookUrl)" target="_blank" :href="facebookUrl"><img src="../assets/facebook.png" alt="facebook" /></a>
        </div>
        <div>
            <a @click="social_click(twitterUrl)" target="_blank" :href="twitterUrl"><img src="../assets/twitter.png" alt="twitter" /></a>
        </div>
        <div>
            <a @click="copyClick(myUrl)">
              <span class="tooltiptext" v-if="show" transition="expand">Link copied to clipboard.</span>
              <img src="../assets/link.png" alt="Copy to clipboard" /></a>
        </div>
    </div>
</template>
<script>
import copy from 'copy-to-clipboard'
export default {
    components: {},
    props: {},
    data() {
        return {
            show:false,
            description: 'Annex88 has got achieving brand heat down to a science. Take their formula for a spin to see the method to the radness.',
            myUrl: 'https://www.thescienceofhype.com/'
        };
    },
    computed: {
        twitterUrl: function() {
            return `https://twitter.com/intent/tweet?url=${encodeURIComponent(this.myUrl)}&text=${encodeURIComponent(this.description)}`
        },
        facebookUrl: function() {
            return `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(this.myUrl)}`
        }
    },
    mounted() {},
    methods: {
        social_click: function(url) {
            window.open(url,
                'share',
                'toolbar=0,status=0,width=626,height=436');

            return false;
        },
        copyClick:function(str){
          this.show = true
          copy(str)
          setTimeout(()=>{
            this.show = false
          },2500)
        }
    }
}
</script>
<style lang="scss" scoped>
.share-modal {
    width: 100%;
    height: 100%;
    display: block;
    position: absolute;
    top: 0;
    margin: auto;

    div {
        display: block;
        width: (100/3) * 1%;
        float: left;
        text-align: center;
        height: 100%;
        position: relative;

        a {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 60%;
            background-size: cover;
            cursor: pointer;

            img {
                width: 100%;
                display: block;
            }
        }
    }
}
.close-btn {
  background-image: url("/assets/button_close.png");
  background-size: cover;
  background-repeat: no-repeat;
  width: 60px;
  height: 60px;
  float: right;
  cursor: pointer;
}

/* Tooltip container */
.tooltip {
    position: relative;
}

/* Tooltip text */
.tooltiptext {
    visibility: hidden;
    width: 120px;
    background-color: #555;
    color: #fff;
    text-align: center;
    padding: 5px 0;
    border-radius: 6px;

    /* Position the tooltip text */
    position: absolute;
    z-index: 1;
    bottom: 125%;
    left: 50%;
    margin-left: -60px;

    /* Fade in tooltip */
    opacity: 0;
    transition: opacity 0.3s;
}

/* Tooltip arrow */
.tooltiptext::after {
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: #555 transparent transparent transparent;
}

/* Show the tooltip text when you mouse over the tooltip container */
.tooltiptext {
    visibility: visible;
    opacity: 1;
}
</style>